.gen_hash {
  text-align: left;
  padding: 10px;
  width: 95%;
  margin: 10px auto;
}
.gen_hash button {
  margin-top: 10px;
}
.gen_hash label {
  font-weight: bold;
}
